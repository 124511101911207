export const environment = {
  "ACC_INTEGRATION_BUTTON": false,
  "AMPLITUDE_API_KEY": "e17ac9b4d48caa1791c976f272917a94",
  "API_URL": "https://securetest.chronicle.rip",
  "APP_BASE_URL": "https://securetest.chronicle.rip",
  "CEMETERIES_URL_360": [
    "cheltenham_memorial_park_cemetery",
    "basinger-cemetery-okeechobee"
  ],
  "CEMETERIES_URL_3D": "cheltenham_memorial_park_cemetery",
  "CESIUM_ION_TOKEN": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YTlkZDIzZi01MmJmLTRkYjItYjVhMy01MDkwYWI0NDk5OTYiLCJpZCI6MjAxODAxLCJpYXQiOjE3MTAzOTYwMDV9.lFUjpsFZQtBI-NHaE_Emg-LzlHfXy24V_YgvdB9aEVM",
  "DEMO_FRONTEND": "https://demo.chronicle.rip",
  "ENC_KEY": "INA45",
  "GOOGLE_ANALYTICS_ID": "",
  "GOOGLE_API_KEY": "",
  "GOOGLE_TAG_MANAGER_ENABLED": true,
  "GOOGLE_TAG_MANAGER_ID": "GTM-MHVSDRMX",
  "MAPBOX_ACCESS_TOKEN": "",
  "PATH_PANORAMA": "https://storage.googleapis.com/chronicle-rip.appspot.com/360",
  "PRODUCTION": false,
  "STRIPE_PUBLISH_KEY": "pk_test_51L5UjFKQMYNHPlmfkaiLiF5EYzcwwOoMSv4INDGJ76ltuDakuMfTX8Kzd7xJuUYFj31UKeFrFsQhxZsMsQHAu6Qj009yNknsQh",
  "SUPPORT_BOARD_ENABLED": false,
  "SUPPORT_BOARD_ID": 794485047,
  "SURVEY_MONKEY_SCRIPT": "",
  "WSS_URL": "wss://securetestms.chronicle.rip"
};
